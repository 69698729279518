import React from 'react'

import * as styles from '../styles/actividades.module.css'

export default function Actividades() {
  return (
    <div className={styles.actCont}>
      <div className={styles.actRosarito}>
        <h1>QUE HACER EN ROSARITO, BAJA CALIFORNIA</h1>

        <div className={styles.actFull}>
          <div style={{ backgroundImage: `url('/1 RESORT CODE BEF.jpg')` }}>
            <h3>Hoteles Bellafer</h3>
          </div>
          {/* <img src="/1 RESORT CODE BEF.jpg" alt="actividad" /> */}
        </div>

        <div className={styles.actDiv}>
          <div className={styles.actDivImg}>
            <div style={{ backgroundImage: `url('/racers.jpg')` }}>
              {/* <img src="/racers.png" alt="actividad" /> */}
              <h3>Racers</h3>
            </div>
          </div>
          <div className={styles.actDivImg}>
            <div style={{ backgroundImage: `url('/shutterstock_486675022.jpg')` }}>
              {/* <img src="/shutterstock_486675022.jpeg" alt="actividad" /> */}
              <h3>Viñedos</h3>
            </div>
          </div>
          <div className={styles.actDivImg}>
            <div style={{ backgroundImage: `url('/EijAj_hU0AA5A8g.jpg')` }}>
              {/* <img src="/EijAj_hU0AA5A8g.jpg" alt="actividad" /> */}
              <h3>Gastronomia</h3>
            </div>
          </div>
        </div>

        <div className={styles.actFull}>
          <div style={{ backgroundImage: `url('/shutterstock_352166396.jpg')` }}>

            {/* <img src="/shutterstock_352166396.jpeg" alt="actividad" /> */}
            <h3>Playas en clima mediterraneo</h3>
          </div>
        </div>

        <div className={styles.actFull}>
          <div style={{ backgroundImage: `url('/02.jpg')` }}>

            {/* <img src="/02.jpg" alt="actividad" /> */}
            <h3>Centro comercial y Medico de Primer Nivel</h3>
          </div>
        </div>

        <div className={styles.actDiv}>
          <div className={styles.actDivImg}>
            <div style={{ backgroundImage: `url('/ABCD4484.jpg')` }}>

              {/* <img src="/ABCD4484.jpg" alt="actividad" /> */}
              <h3>Aries Vacation Club</h3>
            </div>
          </div>
          <div className={styles.actDivImg}>
            <div style={{ backgroundImage: `url('/close-up-of-male-golfer-teeing-off.jpg')` }}>

              {/* <img src="/close-up-of-male-golfer-teeing-off.jpg" alt="actividad" /> */}
              <h3>Golf</h3>
            </div>
          </div>
          <div className={styles.actDivImg}>
            <div style={{ backgroundImage: `url('/joe-neric-EGzkhZyFRX4-unsplash.jpg')` }}>

              {/* <img src="/joe-neric-EGzkhZyFRX4-unsplash.jpg" alt="actividad" /> */}
              <h3>Motos</h3>
            </div>
          </div>
        </div>

        <div className={styles.actFull} style={{ height: '90vh' }}>
          <div style={{ backgroundImage: `url('/MasterPlanPuntazul.jpg')` }}>

            {/* <img src="/MasterPlanPuntazul.jpg" alt="actividad" /> */}
            <h3>Puntazul</h3>
          </div>
        </div>

        <div className={styles.actFull}>
          <div style={{ backgroundImage: `url('/_DSC3460_Small.jpg')` }}>

            {/* <img src="/_DSC3460_Small.jpg" alt="actividad" /> */}
            <h3>Las mejores amenidades y experiencias, En Puntazul Residencial en el corazón de Rosarito B.C. a solo 300 mts de la playa</h3>
          </div>
        </div>
      </div>

      <div className={styles.actMaz}>
        <h1>QUE HACER EN MAZATLÁN, SINALOA</h1>

        <div className={styles.actDiv}>
          <div className={styles.actDivImg}>
            <div style={{ backgroundImage: `url('/shutterstock_1796380126.jpg')` }}>

              {/* <img src="/shutterstock_1796380126.jpeg" alt="actividad" /> */}
              <h3>Impactantes Atardeceres</h3>
            </div>
          </div>
          <div className={styles.actDivImg}>
            <div style={{ backgroundImage: `url('/shutterstock_1284317320.jpg')` }}>

              {/* <img src="/shutterstock_1284317320.jpg" alt="actividad" /> */}
              <h3>Centro Historico</h3>
            </div>
          </div>
          <div className={styles.actDivImg}>
            <div style={{ backgroundImage: `url('/shutterstock_139654466.jpg')` }}>

                            {/* <img src="/shutterstock_139654466.jpeg" alt="actividad" /> */}
              <h3> 2do Malecón más grande del mundo</h3>
            </div>
          </div>
        </div>

        <div className={styles.actFull}>
          <div style={{ backgroundImage: `url('/Panorama-steromztln-Small.jpg')` }}>

            {/* <img src="/Panorama-steromztln-Small.jpg" alt="actividad" /> */}
            <h3>Paradisíaca Playa Residencial</h3>
          </div>
        </div>

        <div className={styles.actFull}>
          <div style={{ backgroundImage: `url('/VISTA LOCALES INTEIORES.jpg')` }}>

            {/* <img src="/VISTA LOCALES INTEIORES.jpg" alt="actividad" /> */}
            <h3>Centro Comercial y Médico de Primer Nivel</h3>
          </div>
        </div>

        <div className={styles.actDiv}>
          <div className={styles.actDivImg}>
            <div style={{ backgroundImage: `url('/shutterstock_1544124674.jpg')` }}>

              {/* <img src="/shutterstock_1544124674.jpeg" alt="actividad" /> */}
              <h3>Basílica de la Inmaculada Concepción</h3>
            </div>
          </div>
          <div className={styles.actDivImg}>
            <div style={{ backgroundImage: `url('/_DSC8165.jpg')` }}>

              {/* <img src="/_DSC8165.jpg" alt="actividad" /> */}
              <h3>Teatro Ángela Peralta</h3>
            </div>
          </div>
          <div className={styles.actDivImg}>
                        <div style={{ backgroundImage: `url('/shutterstock_1554104138.jpg')` }}>

                            {/* <img src="/shutterstock_1554104138.jpeg" alt="actividad" /> */}
              <h3> Zona Dorada</h3>
            </div>
          </div>
        </div>

        <div className={styles.actFull} style={{ height: '110vh' }}>
          <div style={{ backgroundImage: `url('/MasterPlanMazatlan.jpg')` }}>

            {/* <img src="/MasterPlanMazatlan.jpg" alt="actividad" /> */}
            <h3>Las Puertas D'Mazatlán</h3>
          </div>
        </div>

        <div className={styles.actFull}>
            <div style={{ backgroundImage: `url('/277808659_5186266948101120_5921480981290356283_n.jpg')` }}>

            {/* <img src="/277808659_5186266948101120_5921480981290356283_n.jpg" alt="actividad" /> */}
            <h3>Las mejores amenidades y experiencias, en Las Puertas D´Mazatlán Frente a la Playa </h3>
          </div>
        </div>
      </div>
    </div>
  )
}
